var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ax-table',{attrs:{"headers":_vm.defaultTasksHeaders,"active-headers":_vm.activeHeaders,"items":_vm.tasks,"options":_vm.tableStateLocal,"server-items-length":_vm.serverItemsLength,"hide-default-footer":_vm.hideDefaultFooter,"column-select":_vm.columnSelect,"item-key":"id"},on:{"update:options":function($event){_vm.tableStateLocal=$event},"columns-updated":function (cols) { return _vm.updateQueryState(
          { columns: cols.map(function (ref) {
                  var value = ref.value;

                  return value;
                }) },
          { bypassApiUrlUpdate: true }
        ); }},scopedSlots:_vm._u([{key:"item.payload.patch_id",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          path: ("/manage/tasks/" + (item.id)),
          query: {
            o: _vm.orgId,
            frompage: '/manage/tasks',
            trackSource: 'Click: View Task Details Via CVE Link',
          },
        }}},[_vm._v(" "+_vm._s(item.payload.patch_id)+" ")])]}},{key:"item.task_type",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("kebabToReadable")(item.task_type))+" ")]}},{key:"item.payload.severity",fn:function(ref){
        var item = ref.item;
return [_c('ax-chip',{attrs:{"color":_vm.getColorFromSeverity(item.payload.severity)}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(item.payload.severity))+" ")])]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatUTCToZonedTime")(item.created_at))+" ")]}},{key:"item.source",fn:function(ref){
        var item = ref.item;
return [(item.source === 'CrowdStrike')?_c('ax-crowdstrike-logo',{staticClass:"tw-m-auto",attrs:{"height":"30"}}):(item.source === 'Automox')?_c('ax-automox-logo',{staticClass:"tw-m-auto",attrs:{"glyph":"","height":"30","width":"30"}}):_c('span',[_vm._v(_vm._s(item.source))])]}},{key:"item.devices_total",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"tw-flex tw-justify-end"},[_vm._v(_vm._s(item.devices_total))])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"tw-flex tw-capitalize",attrs:{"data-test-id":"status-column-text"}},[_vm._v(_vm._s(_vm._f("snakeToReadable")(item.status)))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('ax-table-row-actions',[_c('v-list-item',{attrs:{"to":{
            path: ("/manage/tasks/" + (item.id)),
            query: {
              o: _vm.orgId,
              frompage: _vm.$route.path,
              trackSource: 'Click: View Task Details Row Action',
            },
          }}},[_vm._v(" Review ")]),(_vm.getTaskExportUrl(_vm.orgId, item))?_c('v-list-item',{attrs:{"href":_vm.getTaskExportUrl(_vm.orgId, item),"download":""},on:{"click":function($event){return _vm.exportCsvClicked(item)}}},[_vm._v(" Export Detailed CSV ")]):_vm._e(),(_vm.removeActionIsApplicable(item.status))?_c('v-list-item',{on:{"click":function($event){_vm.taskToBeRemoved = item;
            _vm.showRemoveTasksModal = true;}}},[_vm._v(" "+_vm._s(_vm.$t('tasks.taskDetails.remove'))+" ")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"tw-pt-6 ax-text-light"},[_c('p',{staticClass:"tw-font-semibold"},[_vm._v("You don't have any tasks.")]),_c('p',{staticClass:"tw--mt-3 tw-flex tw-justify-center tw-items-center"},[_c('ax-button',{staticClass:"tw-mr-1",attrs:{"mode":"tertiary","inline":"","aria-label":"Add a task"},on:{"click":_vm.addTaskClicked}},[_vm._v(" Click here ")]),_vm._v(" to add one. ")],1)])]},proxy:true},_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)}),_c('remove-tasks-modal',{attrs:{"task-ids":_vm.taskToBeRemoved ? [_vm.taskToBeRemoved.id] : []},on:{"close-modal":function($event){_vm.showRemoveTasksModal = !_vm.showRemoveTasksModal},"tasks-removed":function($event){return _vm.removeTableRowCallback()}},model:{value:(_vm.showRemoveTasksModal),callback:function ($$v) {_vm.showRemoveTasksModal=$$v},expression:"showRemoveTasksModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }