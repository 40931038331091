var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-table',{staticClass:"ax-task-devices-table",attrs:{"headers":_vm.headers,"items":_vm.devices,"server-items-length":_vm.serverItemsLength,"options":_vm.tableState,"column-select":false,"footer-props":_vm.footer,"item-key":"id","no-data-text":"No devices"},on:{"update:options":function($event){_vm.tableState=$event}},scopedSlots:_vm._u([{key:"item.private_ips",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('ax-ip-address-column',{attrs:{"ips":{
          publicAddresses: item.public_ips,
          privateAddresses: item.private_ips,
        },"no-data-text":"No IP Found"}})],1)]}},{key:"item.device_status",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"tw-flex tw-items-center"},[_c('div',{staticClass:"tw-w-4 tw-h-4 tw-rounded-md tw-mr-4",style:({ backgroundColor: _vm.TaskStatusColorMap[item.device_status] })}),_c('span',[_vm._v(_vm._s(_vm._f("snakeToReadable")(item.device_status)))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }