import { render, staticRenderFns } from "./CveExpander.vue?vue&type=template&id=53dec6f6&scoped=true&"
import script from "./CveExpander.vue?vue&type=script&lang=ts&"
export * from "./CveExpander.vue?vue&type=script&lang=ts&"
import style0 from "./CveExpander.vue?vue&type=style&index=0&id=53dec6f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53dec6f6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
